<template>
  <div id="index" ref="appRef" style="padding: 0 40px">
    <!-- <audio ref="audio" :src="music_path" >
    </audio> -->

    <div class="bg">
      <div class="host-body">
        <!-- <dv-loading v-if="loading">Loading...</dv-loading> -->
        <div>
          <div class="d-flex jc-center" style="margin-top: 70px">
            <dv-decoration-10 class="dv-dec-10" />
            <div class="d-flex jc-center">
              <dv-decoration-8
                class="dv-dec-8"
                :color="['#568aea', '#000000']"
              />
              <div class="title">
                <span class="title-text">数据预警调度系统</span>
                <dv-decoration-6
                  class="dv-dec-6"
                  :reverse="true"
                  :color="['#50e3c2', '#67a1e5']"
                />
              </div>
              <dv-decoration-8
                class="dv-dec-8"
                :reverse="true"
                :color="['#568aea', '#000000']"
              />
            </div>
            <dv-decoration-10 class="dv-dec-10-s" />
          </div>
          <div class="d-flex jc-between px-2">
            <div class="d-flex aside-width"></div>
            <div class="d-flex aside-width">
              <div class="react-right mr-4 react-l-s">
                <span class="react-after"></span>
                <span class="text"
                  >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
                >
              </div>
            </div>
          </div>
        </div>
            <div
              class="content-box"
              style="

              "
            >
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else  style="width:25%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.jiaji_order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                    margin: 0 auto
                  "
                >
                  加急<br /><br />
                  {{ order_data.jiaji_order.num }}单
                </dv-decoration-9>
              </div>
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else  style="width:25%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                    margin: 0 auto
                  "
                >
                  正常<br /><br />
                  {{ order_data.order.num }}单
                </dv-decoration-9>
              </div>
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else  style="width:25%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.lack_order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                    margin: 0 auto
                  "
                >
                  缺货<br /><br />{{ order_data.lack_order.num }}单
                </dv-decoration-9>
              </div>
              <dv-loading v-if="loading">Loading...</dv-loading>
              <div v-else  style="width:25%;display:inline-block;">
                <dv-decoration-9
                  :dur="order_data.after_sale_order.dur"
                  style="
                    width: 240px;
                    height: 240px;
                    text-align: center;
                    font-size: 30px;
                    margin: 0 auto
                  "
                >
                  异常<br /><br />{{ order_data.after_sale_order.num }}单
                </dv-decoration-9>
              </div>
            </div>
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 1">
          <div>
            <dv-scroll-board
              :config="jaiji_order"
              style="
                width: 440px;
                height: 820px;
                margin-top: 20px;
                padding: 2px;
              "
            />
          </div>
          <div>
            <dv-scroll-board
              :config="order"
              style="
                width: 440px;
                height: 820px;
                margin-top: 20px;
                padding: 2px;
              "
            />
          </div>
          <div>
            <dv-scroll-board
              :config="lack_order"
              style="
                 width: 440px;
                height: 820px;
                margin-top: 20px;
                padding: 2px;
              "
            />
          </div>

          <div>
            <dv-scroll-board
              :config="after_sale_order"
              style="
                width: 440px;
                height: 820px;
                margin-top: 20px;
                padding: 2px;
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
export default {
  mixins: [drawMixin],
  data() {
    return {
      order_data: {},
      music_path: "https://imgs.xinfucai.cn/Static/audio/xindingdan.mp3",
      jaiji_order: {
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>加急订单</span>"],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 10,
        carousel: "single",
        waitTime: 4000,
      },
      order: {
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>正常订单</span>"],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 10,
        carousel: "single",
        waitTime: 4000,
      },
      lack_order: {
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>缺货订单</span>"],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 10,
        carousel: "single",
        waitTime: 4000,
      },
      after_sale_order: {
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>异常订单</span>"],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 10,
        carousel: "single",
        waitTime: 4000,
      },
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      websock: null,
      cdata: {},
      is_play: false,
      map: null,
      dataTree: [],
    };
  },
  components: {},
  mounted() {
    this.timeFn();
    // this.cancelLoading();
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    initWebSocket() {
      //初始化weosocket
      // const wsuri = "ws://127.0.0.1:9502";
      // const wsuri = "wss://ws.hzsp668.com";
      //  const wsuri = "wss://ws.hfx.xinfucai.cn";
      // const wsuri = "wss://ws.kaida668.com";
      const wsuri = "wss://ws.jiaqifucai.cn";
      // const wsuri = "ws://cxjc.ws.hzsp668.com";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      // let region_id = "138_order";
      // let region_id = "52_order";
      // let region_id = "343_order";
      let region_id = "140_order";
      // let region_id = "163_order";
      let original_data = {
        class: "Login",
        action: "loginDp",
        content: {
          region_id,
        },
      };
      this.websocketsend(JSON.stringify(original_data));
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      console.log(redata);
      this.order_data = redata.order_data;
      this.cancelLoading();
      let jaiji_order={
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>配送中</span>"],
        data: redata.jaiji_order,
        align: ["center"],
        rowNum: 7,
        carousel: "single",
        waitTime: 4000,
      };
      let order={
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>配送中</span>"],
        data: redata.order,
        align: ["center"],
        rowNum: 7,
        carousel: "single",
        waitTime: 4000,
      };
      let lack_order={
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>配送中</span>"],
        data: redata.lack_order,
        align: ["center"],
        rowNum: 7,
        carousel: "single",
        waitTime: 4000,
      };
      let after_sale_order={
        headerHeight: 100,
        // header: ["<span style='font-size:30px;'>配送中</span>"],
        data: redata.after_sale_order,
        align: ["center"],
        rowNum: 7,
        carousel: "single",
        waitTime: 4000,
      };

      this.jaiji_order = jaiji_order;
      this.order = order;
      this.lack_order = lack_order;
      this.after_sale_order = after_sale_order;
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      this.initWebSocket();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/index.scss";
.dv-scroll-board .rows .row-item {
  line-height: normal !important;
  height: auto !important;
  padding: 10px;
}
.dv-scroll-board .rows .row-item {
  line-height: normal !important;
  height: auto !important;
  padding: 10px;
}
.amap-marker-label {
  border: 1px solid #eee;
  color: #fff;
  background: #DEB887;
}
</style>
